import './bootstrap';
import $ from 'jquery';

import '@popperjs/core';
import 'bootstrap';
import 'slick-carousel';

window.jQuery = $;
window.$ = $;

$(function () {
	checkWindowScroll();
	contentOffset();
	initSliders();

	$(window).on('scroll', function () {
		checkWindowScroll();
	});

	// Burger click
	$('#burger').click(function () {
		open = !open;
		toggleMenu(open);
	});

	// Smooth scroll.
	$('a[href*="#"]')
		.not('[href="#"]')
		.not('[href="#0"]')
		.on('click', function (event) {
			if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
				var target = $(this.hash);
				var offset = $('header').outerHeight();
				target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				if (target.length) {
					event.preventDefault();
					$('html, body').animate(
						{
							scrollTop: target.offset().top - offset,
						},
						1000,
					);
				}
			}
		});
});

/**
 * @description checks the scroll value of the window and adds a class to the header if the window is scrolled
 */
function checkWindowScroll() {
	let scroll = $(window).scrollTop();
	if (scroll > 0) {
		$('header').addClass('scroll');
	} else {
		$('header').removeClass('scroll');
	}
}

/**
 * @description initialise the slick sliders for the page
 */
let initSliders = () => {
	// generic slider example
	// go to - https://kenwheeler.github.io/slick/ - for documentation
	$('.book-carousel').slick({
		arrows: false,
		autoplay: false,
		dots: false,
		infinite: false,
		initialSlide: 1,
		slidesToScroll: 1,
		slidesToShow: 1,
	});

	// slider arrow functionality
	// $('.slick-{arrow-name}.slick-prev').click(function () {
	//   $('.{carousel-name}').slick('slickPrev');
	// });
	// $('.slick-{arrow-name}.slick-next').click(function () {
	//   $('.{carousel-name}').slick('slickNext');
	// });
};

/**
 * @description offset elements labeled with the offset class by the height of the header
 */
let contentOffset = () => {
	let content = $('.image-bg');
	let header = $('header');

	if (content.length && header.length) {
		let height = $(header).outerHeight();
		$(content).css('padding-top', `${height}px`);
	}
};

/**
 * @description handles opening and closing of the mobile menu on the site
 *
 * @param {*} open flag indicating whether or not the menu is in an open state
 */
var open = false;
let toggleMenu = (open) => {
	$('#burger').toggleClass('open', open);
	$('#navigation-mobile ul').toggleClass('open', open);
};
